<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
         <el-form-item label="租户" prop="tenantId" :label-width="formLabelWidth">
            <el-select v-model="formInline.tenantId" clearable placeholder="请选择租户" >
              <el-option v-for="(item,index) in sysTenantList" :key="index" :label="item.tenantName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        <!-- <el-form-item label="名称" prop="orgName">
          <el-input v-model="formInline.orgName" placeholder="请输入名称"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">搜索</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-button type="primary" icon="el-icon-plus"  @click="addHandle()">新增</el-button>
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column label="租户" prop="tenantName" width="180"></el-table-column>
        <el-table-column label="车型" prop="modelName" width="150">
        </el-table-column>
        <el-table-column label="年款" prop="modelYear" width="100">
        </el-table-column>
        <el-table-column label="手册类型" prop="manualTypeName" width="180"></el-table-column>
        <el-table-column label="应用类型" prop="appType" width="120">
          <template slot-scope="{row}">
            <span v-if="row.appType === 1" >PC端</span>
            <span v-if="row.appType === 2" >手机端</span>
            <span v-if="row.appType === 3" >车机端</span>
          </template>
        </el-table-column>
         <el-table-column label="展示方式" prop="displayMode" width="120">
          <template slot-scope="{row}">
            <span v-if="row.displayMode === 1" >html</span>
            <span v-if="row.displayMode === 2" >pdf</span>
            <span v-if="row.displayMode === 3" >img</span>
          </template>
        </el-table-column>
      
        <el-table-column label="访问路径" prop="webHost" width="260"></el-table-column>
          <el-table-column label="首页面" prop="indexConfig" width="200"></el-table-column>
        <el-table-column label="操作" fixed="right" width="200">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="handelDetail(row)">详情</el-button>
            <el-button type="primary" size="mini" @click="handleEdit(row)">编辑</el-button>
            <el-button  type="primary" size="mini" @click="handleDelete(row)" style="background:#ff5722">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate">
          <el-form v-if="dialogStatus === 'detail'" ref='dataFormDetail' :model="dataForm" label-position="center">
          <el-form-item label="租户" prop="tenantName" :label-width="formLabelWidth">
            <el-input v-model="dataForm.tenantName" readonly></el-input>
          </el-form-item>
          <el-form-item label="车型" prop="modelName" :label-width="formLabelWidth">
             <el-input v-model="dataForm.modelName" readonly></el-input>
          </el-form-item>
          <el-form-item label="年款" prop="modelYear" :label-width="formLabelWidth">
             <el-input v-model="dataForm.modelYear" readonly></el-input>
          </el-form-item>
          <el-form-item label="手册类型" prop="manualTypeName" :label-width="formLabelWidth">
            <el-input v-model="dataForm.manualTypeName" readonly></el-input>
          </el-form-item>
          <el-form-item  label="应用类型" prop="appType" :label-width="formLabelWidth">
              <el-input v-if="dataForm.appType === 1" v-model="appTypeArr[0]" readonly></el-input>
              <el-input v-if="dataForm.appType === 2" v-model="appTypeArr[1]" readonly></el-input>
              <el-input v-if="dataForm.appType === 3" v-model="appTypeArr[2]" readonly></el-input>
          </el-form-item>
          <el-form-item  label="展示方式" prop="displayMode" :label-width="formLabelWidth">
            <el-input v-if="dataForm.displayMode === 1" v-model="displayModeArr[0]" readonly></el-input>
            <el-input v-if="dataForm.displayMode === 2" v-model="displayModeArr[1]" readonly></el-input>
            <el-input v-if="dataForm.displayMode === 3" v-model="displayModeArr[2]" readonly></el-input>
          </el-form-item>
           <el-form-item label="访问路径" prop="webHost"  :label-width="formLabelWidth">
             <el-input v-model="dataForm.webHost"   readonly></el-input>
          </el-form-item>
            <el-form-item label="数据包路径" prop="packagePath" :label-width="formLabelWidth">
            <el-input v-model="dataForm.packagePath"   readonly></el-input>
          </el-form-item>
          <el-form-item label="首页面" prop="indexConfig" :label-width="formLabelWidth">
            <el-input v-model="dataForm.indexConfig" readonly></el-input>
          </el-form-item>
        
          <el-form-item label="导航目录" prop="catalogConfig"  :label-width="formLabelWidth">
               <el-input v-model="dataForm.catalogConfig"   readonly></el-input>
          </el-form-item>
          <el-form-item label="明细页面" prop="detailConfig"  :label-width="formLabelWidth">
               <el-input v-model="dataForm.detailConfig"   readonly></el-input>
          </el-form-item>
            <el-form-item label="备注" prop="remark"  :label-width="formLabelWidth">
            <el-input type="textarea"  rows="2" v-model="dataForm.remark"  readonly></el-input>
          </el-form-item>
        </el-form>
        <el-form v-if="dialogStatus === 'edit' || dialogStatus === 'add'"  ref='dataForm' :model="dataForm" :rules="fromrules" label-position="center"  :close-on-click-modal="false">
           <el-form-item label="租户" prop="tenantId" :label-width="formLabelWidth">
            <el-select v-model="dataForm.tenantId"  @change="tenantChanged"  clearable placeholder="请选择租户" >
              <el-option v-for="(item,index) in sysTenantList" :key="index" :label="item.tenantName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
         <el-form-item label="车型" prop="modelCode" :label-width="formLabelWidth">
            <select-tree ref="modelSelectTree" :options="trainList" v-model="dataForm.modelCode"  :props="defaultProps"  @getCurrentNode="getCurrentNode" placeholder="请选择车型"  />
          </el-form-item>
          <el-form-item label="年款" prop="modelYear" :label-width="formLabelWidth">
            <el-select v-model="dataForm.modelYear">
              <el-option v-for="(item,index) in trainYear" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手册类型" prop="manualType" :label-width="formLabelWidth">
            <el-select v-model="dataForm.manualType">
              <el-option v-for="(item,index) in manualTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="应用类型" prop="appType" :label-width="formLabelWidth">
            <el-radio v-model="dataForm.appType"   :label="1">PC端</el-radio>
            <el-radio v-model="dataForm.appType"   :label="2">手机端</el-radio>
            <el-radio v-model="dataForm.appType"   :label="3">车机端</el-radio>
          </el-form-item>
          <el-form-item label="展示方式" prop="displayMode" :label-width="formLabelWidth">
            <el-radio v-model="dataForm.displayMode"  :label="1">html</el-radio>
            <el-radio v-model="dataForm.displayMode"  :label="2">pdf</el-radio>
            <el-radio v-model="dataForm.displayMode"  :label="3">img</el-radio>
          </el-form-item>
          <!-- <el-form-item label="名称" prop="manualName" :label-width="formLabelWidth">
            <el-input v-model="dataForm.orgName" @input="e => dataForm.orgName= validForbid(e)" show-word-limit maxlength="30"   style="padding:0 48px 0 0"   placeholder="请输入名称"></el-input>
          </el-form-item> -->
           <el-form-item label="访问路径" prop="webHost"  :label-width="formLabelWidth">
              <el-input v-model="dataForm.webHost" placeholder="请输入访问路径"  show-word-limit maxlength="100"  style="padding:0 48px 0 0"   ></el-input>
          </el-form-item>
          <el-form-item label="数据包路径" prop="packagePath" :label-width="formLabelWidth">
              <el-input v-model="dataForm.packagePath" placeholder="请输入数据包路径"  show-word-limit maxlength="100"  style="padding:0 48px 0 0"   ></el-input>
          </el-form-item>
           <el-form-item label="首页面" prop="indexConfig" :label-width="formLabelWidth">
            <el-input v-model="dataForm.indexConfig" placeholder="请输入首页面"  show-word-limit maxlength="100"  style="padding:0 48px 0 0"   ></el-input>
          </el-form-item>
          <el-form-item label="导航目录" prop="catalogConfig" :label-width="formLabelWidth">
               <el-input v-model="dataForm.catalogConfig" placeholder="请输入导航目录"  show-word-limit maxlength="100"  style="padding:0 48px 0 0"   ></el-input>
          </el-form-item>
          <el-form-item label="明细页面" prop="detailConfig" :label-width="formLabelWidth">
               <el-input v-model="dataForm.detailConfig" placeholder="请输入明细页面"  show-word-limit maxlength="100"  style="padding:0 48px 0 0"   ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark"  :label-width="formLabelWidth">
            <el-input type="textarea"  rows="2" v-model="dataForm.remark"  show-word-limit maxlength="200"  style="padding:0 48px 0 0"   placeholder="请输入备注"></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="dialogStatus === 'add' ? addClick('dataForm') : editClick('dataForm')">
              立即提交
            </el-button>
            <el-button  v-if="dialogStatus === 'add'"   @click="resetForm('dataForm')">
              重置
            </el-button>
             <el-button  v-if="dialogStatus === 'edit'"  :disabled="true"   @click="resetForm('dataForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import SelectTree from '@/components/TreeView/SelectTree.vue';
import { tenantList,trainListByTenant,modelYearList,fdmManualList, fdmManualQuery,fdmManualType, fdmManualAdd,fdmManualEdit, fdmManualDel} from '@/api/api.js'
export default {
  name: 'layoutContainer',
  components: { Pagination, SelectTree  },
  data () {
    return {
      formInline: {
        orgName: '',
        tenantId: ''
      },
      dataForm: {
              id: '',
        tenantId: '',
      tenantName: '',
        modelCode: '',
       modelName: '',
      modelYear: '',
     manualType: '',
     manualTypeName: '',
        appType: '',
        displayMode: '',
        manualName: '',
         webHost: '',
        packagePath: '',
        indexConfig: '',
        catalogConfig: '',
        detailConfig: '',
        remark: ''
      },
      dialogFormVisible: false,
      formLabelWidth: '100px',
      appTypeArr:["PC端","手机端","车机端"],
      displayModeArr:["html","pdf","img"],
      dialogStatus: '',
      textMap: {
        edit: '编辑手册',
        add: '新增手册'
      },
      whether: false,
      resultList: [],
      sysTenantList: [],
      trainList: [],
      trainYear: [],
      manualTypeList: [],
      pagesize: 10,
      currentPage: 1,
      total: 0,
      fromrules: {
        tenantId: [{ required: true, message: '请选择租户', trigger: 'blur' }],
        modelCode:  [{ required: true, message: '请选择车型', trigger: 'blur' }],
        modelYear: [{ required: true, message: '请选择年款', trigger: 'blur' }],
        manualType: [{ required: true, message: '请选择手册类型', trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 数据
    dataList () {
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        tenantId: this.formInline.tenantId
      }
      fdmManualList(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    // 搜索
    onSubmit () {
      this.currentPage=1
      var params ={
        page: this.currentPage,
        limit: this.pagesize,
        tenantId: this.formInline.tenantId
      }
      fdmManualQuery(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
     // 租户列表
    getSysTenantList () {
      tenantList().then(res=>{
        this.sysTenantList = res.data.data
      })
    },
    getTrainList(tenantId){
      var params = {
        tenantId: tenantId
      }
      trainListByTenant(params).then(res => {
        if(res.data.code==100){
          this.trainList = res.data.data
        }
      })
    },
    getTrainYearList(trainId){
      var params = {
        trainId: trainId
      }
      modelYearList(params).then(res => {
        if(res.data.code==100){
          let yearList = res.data.data
          if(!this.campareSameArray(yearList,this.trainYear)){
            if(this.trainYear&&this.trainYear.length>0){
                 this.temp.modelYear=''
            }
            this.trainYear =yearList
          }
          
        }else{
          this.temp.modelYear=''
          this.trainYear =[]
        }
      })
    },
    // 手册列表
    getManualTypeList () {
      fdmManualType().then(res=>{
        this.manualTypeList = res.data.data
      })
    },

   tenantChanged(value){
      if(value!=''){
        this.getTrainList(value)
         this.dataForm.modelCode=''
         this.dataForm.modelYear=''
      }
    },
    campareSameArray(arr1,arr2){
      let ret=false
      if(arr1&&arr1.length>0&&arr2&&arr2.length>0){
         if(arr1.sort().toString()==arr2.sort().toString()){
             ret=true
         }
      }
      return ret
    },
    getCurrentNode(node){
      if(this.dataForm.modelCode!=null&&node!=null){
        this.getTrainYearList(node.id)
        if(this.dataForm.modelCode!=node.id){
           this.dataForm.modelYear=''
        }
      
        this.$refs['dataForm'].validateField('modelCode')
      }
    },
    handleCloseCreate () {
      this.dialogFormVisible = false
      this.$refs.dataForm.resetFields()
    },
    // 重置
    resetForm (dataForm) {
       this.resetTemp()
      if (this.$refs[dataForm].resetFields() !== undefined) {
        this.$refs[dataForm].resetFields()
      }
    },
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()

      }
      
      this.dataList()
    },
    resetTemp () {
      this.dataForm = {
              id: '',
        tenantId: '',
      tenantName: '',
        modelCode: '',
      modelYear: '',
     manualType: '',
        appType: '',
        displayMode: '',
        manualName: '',
        webHost: '',
        packagePath: '',
        indexConfig: '',
        catalogConfig: '',
        detailConfig: '',
        remark: ''
      }
    },
    // 新增
    addHandle () {
      this.resetTemp()
      this.dialogStatus = 'add'
      this.dataForm.modelCode=''
      this.dataForm.modelYear=''
      this.dialogFormVisible = true
    },
    addClick (dataForm) {
      this.$refs[dataForm].validate((valid) => {
        if (valid) {
          var params = new URLSearchParams()
          params.append('tenantId', this.dataForm.tenantId)
          params.append('modelCode', this.dataForm.modelCode)
          params.append('modelYear', this.dataForm.modelYear)
          params.append('manualType', this.dataForm.manualType)
          params.append('appType', this.dataForm.appType)
          params.append('displayMode', this.dataForm.displayMode)
          params.append('webHost', this.dataForm.webHost)
          params.append('packagePath', this.dataForm.packagePath)
          params.append('indexConfig', this.dataForm.indexConfig)
          params.append('catalogConfig', this.dataForm.catalogConfig)
          params.append('detailConfig', this.dataForm.detailConfig)
          params.append('remark', this.dataForm.remark)

          fdmManualAdd(params).then(res => {
            if(res.data.code === 100){
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            }else{
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.error('请完善手册配置信息')
        }
      })
    },
    // 详情
    handelDetail (row) {
      this.resetTemp()
      this.dataForm = Object.assign({}, row)
    
      this.dialogFormVisible = true
      this.dialogStatus = 'detail'
    },
    // 编辑
    handleEdit (row) {
     var _this=this
      this.resetTemp()
      _this.getTrainList(row.tenantId)
      _this.getTrainYearList(row.modelCode)
      this.dataForm = Object.assign({}, row)

      setTimeout(function(){
         _this.$refs.modelSelectTree.initSelected(row.modelName,row.modelCode)
      },0);

      this.dialogStatus = 'edit'
      this.dialogFormVisible = true
    },
    editClick (dataForm) {
      this.$refs[dataForm].validate((valid) => {
        if (valid) {
          var params = new URLSearchParams()
          params.append('id', this.dataForm.id)
          params.append('tenantId', this.dataForm.tenantId)
          params.append('modelCode', this.dataForm.modelCode)
          params.append('modelYear', this.dataForm.modelYear)
          params.append('manualType', this.dataForm.manualType)
          params.append('appType', this.dataForm.appType)
          params.append('displayMode', this.dataForm.displayMode)
          params.append('webHost', this.dataForm.webHost)
          params.append('packagePath', this.dataForm.packagePath)
          params.append('indexConfig', this.dataForm.indexConfig)
          params.append('catalogConfig', this.dataForm.catalogConfig)
          params.append('detailConfig', this.dataForm.detailConfig)
          params.append('remark', this.dataForm.remark)
          fdmManualEdit(params).then(res => {
            if(res.data.code === 100){
              this.$message({
                message: res.data.msg,
                type:'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            }else {
              if(res.data.code === 404){
                this.$message.error('系统出现异常，更新失败')
              }else{
                this.$message.error(res.data.msg)
              }
            }
          })
        } else {
           this.$message.error('请完善手册配置信息')
        }
      })
    },
    // 删除
    handleDelete (row) {
      var _this= this
      this.$confirm('确定刪除 ' + row.manualTypeName + '?', '信息提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        fdmManualDel(row.id).then(res => {
          if(res.data.code === 100){
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            
            if(this.resultList!=null&&this.resultList.length==1){
              this.currentPage =this.currentPage-1
            }
            this.dataList()
          }
        })
      }).catch((error) => {
        _this.$message.error('删除失败')
      })
    }
  },
  mounted () {
    this.dataList()
    this.getSysTenantList()
    this.getManualTypeList()
  }
}
</script>
